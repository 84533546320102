<template>
  <div style="background: white;">
    <Card
      :body-style="{ border: 0}"
      shadow="never"
    >
      <template v-slot:header>{{ $t('56b7720') }}</template>
      <template v-slot:actions>
        <el-button
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="jumpAdd"
        >
          <Icon :src="sendImage" size="12" />
          <span
            style="
              margin-left: 10px;
              color: rgba(0, 164, 255, 1);
              font-size: 14px;
            "
          >{{ $t('5dc734e') }}</span>
        </el-button>
      </template>
      <div v-loading="loading">
        <DisplayCard
          v-for="item in data"
          :key="item.id"
          :data="item"
          :show-actions="true"
          @delete="handleDelete"
          @edit="handleEdit"
        >
          <template v-slot:footer>
            <span class="action-button" @click="handleViewClick(item.id)">
              <span
                style="margin-right: 6px; vertical-align: middle"
              >{{ $t('696c3e0') }}</span>
              <Icon :src="viewImage" size="13" />
            </span>
          </template>
        </DisplayCard>
        <Empty v-if="!(data && data.length)" type="lookup" />
      </div>
      <Pagination :page-info="pageInfo" @changePage="changePage" />
    </Card>
    <ReleaseFormDialog :dialog-data="dialogData" @editSuccess="editSuccess" />
  </div>
</template>

<script>
import "./index.less"
import Pagination from "@/components/Pagination"
import Empty from "@/components/Empty/Factory"
import Card from "@/components/Card"
import Icon from "@/components/Icon"
import ReleaseFormDialog from "@/components/ReleaseFormDialog"

import sendImage from "@/assets/content/lookup_active.png"
import viewImage from "@/assets/content/view.png"

import DisplayCard from "./displayCard"
import api from "@/api/content.lookup"

import { mapGetters, mapActions } from "vuex"
export default {
  name: "RequestManage",
  components: {
    Empty,
    DisplayCard,
    Pagination,
    Card,
    Icon,
    ReleaseFormDialog,
  },
  data() {
    return {
      viewImage,
      sendImage,
      data: [],
      loading: false,
      pageInfo: {
        currentPage: 1,
        size: 8,
        total: 0,
      },
      dialogData: {
        title: "编辑需求",
        type: "ReleaseDemand",
        id: undefined,
        dialogVisible: false,
      },
    }
  },
  computed: {
    ...mapGetters(["dictionaries"]),
  },
  props: {},
  methods: {
    ...mapActions(["getAreaTree", "getDictionary"]),
    editSuccess() {
      this.getList()
    },
    jumpAdd() {
      this.$router.push("/content/lookup/form")
    },
    handleEdit(id) {
      this.dialogData.id = id
      this.dialogData.dialogVisible = true
      // this.$router.push(`/content/request/release?id=${id}`);
    },
    handleSubmit(values) {
      const newValues = { ...values }
      delete newValues.date
      if (values.date) {
        newValues.startTime = values.date[0]
          .toLocaleDateString()
          .replace("/", "-")
          .replace("/", "-")
        newValues.endTime = values.date[1]
          .toLocaleDateString()
          .replace("/", "-")
          .replace("/", "-")
      }
      this.getList(newValues)
    },
    handleReset() {
      this.getList()
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getList()
    },
    handleDelete(id) {
      api.delete(id).then((res) => {
        this.getList()
      })
    },
    handleViewClick(id) {
      this.$router.push({
        path: `/content/lookup/detail?id=${id}`,
        query: { id: id },
      })
    },
    getList(params = {}) {
      this.loading = true
      api
        .get({
          size: this.pageInfo.size,
          page: this.pageInfo.currentPage,
          ...params,
        })
        .then((res) => {
          this.loading = false
          if (res) {
            this.data = res.content.map(item => {
              if (item.caseDescription && item.caseDescription.length > 95) {
                item.caseDescription = item.caseDescription.substring(0, 95).concat("...")
              }
              if (item.demandDescription && item.demandDescription.length > 95) {
                item.demandDescription = item.demandDescription.substring(0, 95).concat("...")
              }
              return item
            })
            this.pageInfo.total = res.page.total
          }
        })
    },
  },
  mounted() {
    this.getList()
    // this.getAreaTree();
    // this.getDictionary({ type: "COUNTRY" });
  },
}
</script>
