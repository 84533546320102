<template>
  <div class="display-card-box">
    <div class="display-card-header">
      <div
        class="status-badge"
        :style="{ color: color['text'], background: color['bg'] }"
      >
        {{ statusStr }}
      </div>
      <div v-if="showActions" class="action-box">
        <el-popconfirm
          v-if="allowDelete"
          :title="$t('8d1023d')"
          @confirm="handleDelete"
        >
          <i slot="reference" class="delete-icon icon"></i>
        </el-popconfirm>
        <i v-if="allowEdit" class="edit-icon icon" @click="handleEdit"></i>
      </div>
    </div>
    <div class="display-card-content">
      <el-row>
        <el-col
          v-for="(item, index) of descMetaData"
          :key="index"
          :span="item.span || 12"
        >
          <DescItem
            :label="item.label"
            :link="data.link"
            :line-count="item.lineCount"
          >
            {{
              item.compute
                ? item.compute(data[item.key], data)
                : data[item.key] | textFilter
            }}
          </DescItem>
        </el-col>
      </el-row>
    </div>
    <div class="display-card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import DescItem from "@/components/Descriptions/Item"

import { STATUS, status2color } from "../constants"

import { mapGetters } from "vuex"

export default {
  name: "DisplayCard",
  components: {
    DescItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descMetaData: [
        { label: this.$t('06618ba'), key: "caseDescription", span: 24 },
        { label: "查询需求描述", key: "demandDescription", span: 24 },
        { label: "查询时间", key: "createTime", span: 24 },
      ],
    }
  },
  computed: {
    ...mapGetters(["areaTree", "areaMap", "dictionaries"]),
    color() {
      return status2color[this.data.status] || status2color["DEFAULT"]
    },
    urgentColor() {
      return this.data.urgentStatus === "EMERGENCY" ? "red" : ""
    },
    statusStr() {
      return STATUS[this.data.status]
    },
    allowDelete() {
      return this.data.status === "UNDER_REVIEW"
    },
    allowEdit() {
      return this.data.status === "UNDER_REVIEW"
    },
  },
  methods: {
    handleDelete() {
      this.$emit("delete", this.data.id)
    },
    handleEdit() {
      this.$emit("edit", this.data.id)
    },
  },
}
</script>

<style scoped>
.display-card-box {
  border: 1px solid #e7edef;
  border-radius: 4px;
  padding: 20px 0;
  margin-bottom: 20px;
}

.display-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.status-badge {
  width: 114px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  border-radius: 0 18px 18px 0;
}

.display-card-content {
  padding: 20px 30px;
}

.icon.delete-icon {
  background: url("../../../../assets/common/delete.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.edit-icon {
  background: url("../../../../assets/common/edit.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon {
  margin: 5px;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.display-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.action-button {
  color: rgba(0, 164, 255, 1);
  font-size: 14px;
  cursor: pointer;
}
</style>
